import * as React from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import { Seo } from "../components/seo"
import { Typography } from "antd"
import PageContent from "../components/page-content"
const { Title } = Typography

const GrowersGallery = (data) => {
  if (!data) return null

  return (
    <Layout>
      <Seo title={"Growers Gallery"} />
      <PageContent>
        <Title>Growers Gallery</Title>
        {/* <h1>Feed Chart</h1> */}
        <covet-pics-widget gallery-embed-id="180758"></covet-pics-widget>
        <Helmet>
          <script src="https://shopify.covet.pics/covet-pics-widget-inject.js"></script>
        </Helmet>
      </PageContent>
    </Layout>
  )
}

export default GrowersGallery
